import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

const AshPlayonPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page ash'} game="ash">
      <ul className="breadcrumb">
        <li>
          <Link to="/ash-echoes/">Ash Echoes</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ash/categories/category_characters.webp"
            alt="Tier List"
          />
        </div>
        <div className="page-details">
          <h1>Play & Reroll Ash Echoes on PC</h1>
          <h2>How to play & reroll Ash Echoes gacha game on your PC.</h2>
        </div>
      </div>
      <div className="page-content">
        <div className="banner play-on-pc bcm">
          <div className="cta">
            <h4>Play & Reroll Ash Echoes on PC</h4>
            <OutboundLink
              href="https://ae.neocraftstudio.com/download"
              target="_blank"
            >
              <Button variant="primary">Official PC Client</Button>
            </OutboundLink>
            <OutboundLink
              href="https://leap.ldplayer.gg/T4ndFUlZ0"
              target="_blank"
            >
              <Button variant="primary">Reroll now on LDPlayer</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Ash Echoes is an RTS (Real Time Strategy) game developed by Aurogon
          Shanghai and published on Global servers by Neocraft. It features a
          sci-fi setting with a varied cast of characters who come from
          different worlds, after a cataclysm known as the “Skyrift Incident”
          begins to tear reality apart (literally).
        </p>
        <SectionHeader title="What is LDPlayer?" />
        <p>
          LDPlayer is the best platform to enjoy mobile games on your PC,
          offering an immersive and enhanced gaming experience. LDPlayer is an
          Android emulator for Windows that is built on 64-bit architecture and
          supports apps and games from the Play Store. You can now enjoy your
          favorite games on your PC instead of just playing them on your phone!
        </p>
        <OutboundLink href="https://leap.ldplayer.gg/T4ndFUlZ0" target="_blank">
          <Button variant="primary">Reroll now on LDPlayer</Button>
        </OutboundLink>
        <SectionHeader title="Benefits of using LDPlayer" />
        <p>Playing Ash Echoes on LDPlayer offers lots of advantages:</p>
        <ul>
          <li>
            Fast rerolling isn't available on PC client, which means that it’s
            hard for your to get the perfect start. LDPlayer, however, supports
            multiple instances, making it perfect for managing multiple accounts
            or rerolling more efficiently.
          </li>
          <li>
            With LDPlayer, you can also use macros to enhance your gaming
            experience, let’s forget the repetitive tasks and save valuable
            time!
          </li>
        </ul>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>CPU: Intel or AMD Processor like x86 or x86_64</li>
          <li>
            System: Win7 / Win8 / Win8.1 / Win10 / Win11 (Do not support Mac
            version)
          </li>
          <li>
            Graphics Driver: Windows DirectX 11 or Graphics driver with OpenGL
            2.0
          </li>
          <li>RAM: 16GB</li>
          <li>Disk Space: Minimum 100GB</li>
          <li>
            VT: Virtualization Technology (Intel VT-x/AMD-V) enabled.{' '}
            <a
              href="https://www.ldplayer.net/blog/how-to-enable-vt.html"
              target="_blank"
              rel="noreferrer"
            >
              Simple Steps to enable VT
            </a>
            .
          </li>
        </ul>
        <p>
          If you have needs for playing multiple instances, it's recommended to
          use the CPU with higher numbers of cores and graphics card with higher
          vram. You can refer to this tutorial{' '}
          <a
            href="https://www.ldplayer.net/blog/how-to-open-as-many-instances-of-LDPlayer-as-possible.html"
            target="_blank"
            rel="noreferrer"
          >
            How to open as many instances of LDPlayer as possible
          </a>
          .
        </p>
        <SectionHeader title="Play Ash Echoes and support Prydwen" />
        <p>
          If you download LDPlayer and play Ash Echoes (or other games) on it,
          using our promotional link, you will support the website. Thank you!
        </p>
        <OutboundLink href="https://leap.ldplayer.gg/T4ndFUlZ0" target="_blank">
          <Button variant="primary">Reroll now on LDPlayer</Button>
        </OutboundLink>
      </div>
    </DashboardLayout>
  );
};

export default AshPlayonPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Ash Echoes | Prydwen Institute"
    description="Play Ash Echoes gacha game on your PC."
    game="ash"
  />
);
